////////////
// HEADER //
////////////
header {
	position: sticky;
	top: 0;
	align-items: center;
	display: flex;
	justify-content: center;
	color: $color-primary;
	min-height: $headerHeight;
	height: $headerHeight;
	max-height: $headerHeight;

	z-index: 10;
	position: relative;

	#logo {
		cursor: pointer;
		position: absolute;
		width: 1em;
		height: 2em;
		left: 2em;
		top: calc($headerHeight / 2 - 1em);
		transition: $transition-medium;

		&:hover {
			transition: $transition-medium;
		}

		&:active {
			transform: scale(0.95);
			transition: $transition-fast;
		}
	}

	nav {
		position: absolute;
		top: 0;
		right: 2em;
		width: calc(100% - 4em - 2em);
		display: flex;
		justify-content: space-between;
		color: $color-primary;
		// background: $gradient-one;
		height: 100%;
		align-items: center;
		text-align: center;
	}

	a {
		display: flex;
		align-items: center;
		text-decoration: none;
		color: $color-primary;
		transition: $transition-medium;
		height: 100%;
		padding: 1em;

		&:hover {
			// color: $color-bg;
			// background: $gradient-two;
			transition: $transition-medium;
		}
	}
}

///////////////////////
// NAVIGATION FOOTER //
///////////////////////
footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	max-width: calc($maxWidth - 4em);
	width: calc(100vw - 4em);
	margin: 0 2em;
	padding: 1.5em 2em;
	background: $gradient-transparant_white;
	border-radius: 0.7em 0.7em 0 0;
	align-items: center;

	nav {
		display: flex;
		flex-direction: row;
		gap: 2em;
		color: $color-link;
	}

	a {
		font-weight: 400;
		opacity: 0.7;

		&:hover {
			opacity: 1;
		}

		&:active {
			opacity: 1;
			transform: scale(0.95);
		}
	}

	.rights {
		text-align: right;
		margin: 0;
	}
}

////////////////
// CREATE NEW //
////////////////
.createNewContent {
	display: flex;
	flex-direction: column;
	gap: 2em;

	// position: relative;
	.flexRow {

		// position: relative;
		// width: 100%;
		h3 {
			width: 20em;
		}

		// .inputField {
		// width: calc(100% - 20em);
		// }
	}
}

/////////////////
// DATE PICKER //
/////////////////
.dateTimePicker {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	gap: 2em;
	position: relative;
	align-items: flex-end;

	.datePicker {
		width: 100%;
	}

	.timePicker {
		min-width: 6.1em;
	}

	@media screen and (max-width: 800px) {
		flex-direction: column;

		.timePicker {
			width: 100%;
		}
	}
}

///////////
// MODAL //
///////////
.modal {
	@include modalBase();
	display: none;
	z-index: 10;

	&.show {
		@include flexCenter();
	}

	&-content {
		@include modalContent();
		display: flex;
		flex-direction: column;
		gap: 1.5em;
	}
}

.modalNavigation {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 2em;
	margin-top: 0.5em;
}

///////////
// ALERT //
///////////
.alert {
	@include modalBase();
	z-index: 101;

	.alert-content {
		@include modalContent();
		@include basicCard();
		width: calc(100% - 4em);
		max-width: 500px;
		padding: 2.5em;
		top: 20vh;
		opacity: 1;
		background: linear-gradient(135deg, rgba(242, 244, 248, 1) 0%, rgba(255, 255, 255, 1) 100%);

		button {
			margin-top: 1.5em;
		}
	}

	.success {
		h2 {
			color: hsl($hue-correct, $saturation-low - 10, $brightness-low);
		}

		button {
			background: $gradient-completed;
		}
	}

	.error {
		color: $color-primary;

		h2 {
			color: hsl($hue-incorrect, $saturation-low, $brightness-low);
		}

		button {
			background: $gradient-incorrect;
			color: white;
		}
	}
}

///////////
// MODAL //
///////////
.close {
	cursor: pointer;
	float: right;
}

/////////////////
// SPIDERCHART //
/////////////////
.spiderChartContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.spiderChart {
		margin-top: -80px;
		// max-height: 270px;

		canvas {
			height: 400px;
			width: 400px;
		}
	}
}