////////////////////
// TEACHER PORTAL //
////////////////////
.teacherPortal {
	.classSelector {
		display: flex;
		gap: 2em;

		.selectClass {
			font-weight: 300;
			cursor: pointer;
			color: $color-primary;
		}
	}

	.overviewOptions {
		display: flex;
		justify-content: space-around;
		align-items: center;
		cursor: pointer;
		border-radius: 1em 1em 0 0;

		h2,
		.current {
			color: white;
			background: linear-gradient(
				0,
				hsl($hue-primary - 10, $saturation-low + 10, $brightness-medium + 10) 0%,
				hsl($hue-primary + 10, $saturation-low, $brightness-medium + 10) 100%
			);
			text-decoration: none;
			width: 100%;
			text-align: center;
			padding: 0.7em;
			border-radius: 1em 0 0 0;
			margin-left: -1em;
			transition: $transition-medium;
			height: 3em;
			max-height: 3em;
		}
		h2 {
			&:hover {
				background: linear-gradient(
					180deg,
					hsl($hue-primary - 10, $saturation-low + 10, $brightness-medium) 0%,
					hsl($hue-primary + 10, $saturation-low, $brightness-medium) 100%
				);
				transition: $transition-medium;
				box-shadow: $standard-inset-box-shadow;
			}
			&:active {
				background: linear-gradient(
					180deg,
					hsl($hue-primary - 10, $saturation-low + 10, $brightness-medium) 0%,
					hsl($hue-primary + 10, $saturation-low + 10, $brightness-medium) 100%
				);
				transition: $transition-medium;
				box-shadow: $standard-inset-box-shadow;
			}
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				border-radius: 1em 1em 0 0;
			}
		}
		.current {
			background: linear-gradient(
				180deg,
				hsl($hue-primary - 10, $saturation-low + 10, $brightness-medium) 0%,
				hsl($hue-primary + 10, $saturation-low, $brightness-medium) 100%
			);
		}
	}
	.createNewClass {
		@include basicCard();
		display: flex;
		flex-direction: column;

		gap: 1em;
	}
	.editClass {
		@include basicCard();
		display: flex;
		flex-direction: column;
		gap: 2em;
	}
}
.buttonRight {
	width: 40%;
	float: right;
}

//////////////////
// LOGIN SIGNUP //
//////////////////
.loginPage {
	width: calc(min(700px, 100vw));
	margin: auto;
}

.loginSignup.is-safari .eyeIcon {
	display: none;
}

.loginSignup {
	max-width: 700px;
	min-width: calc(min(700px, 100%));
	margin: auto;

	.checkboxWrapper {
		display: flex;
		flex-direction: row;
		gap: 1em;
	}

	.passwordField {
		position: relative;
		z-index: 0;

		.eyeIcon {
			position: absolute;
			right: 0;
			width: calc($fontSizeInputFields + 2.4em);
			height: calc($fontSizeInputFields + 2.4em);
			padding: 1.2em;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	.legalDisclaimer {
		color: $color-primary;
		font-size: 0.8em;
		opacity: 0.7;
		max-width: 400px;
		&:hover {
			opacity: 1;
			color: $color-link;
		}
	}
}

//////////////
// STUDENTS //
//////////////
.navigationLabel {
	text-align: center;
}
.studentProgress {
	// width: 100vw;

	sup {
		font-size: 0.7em;
	}

	.flexRowProgress {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		align-items: flex-start;
		gap: 2em;

		@media screen and (max-width: 1100px) {
			flex-direction: column;
			justify-content: flex-start;
		}
	}

	.column {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 2em;
		width: calc(max($maxWidth, 100vw) / 2 - 4em);

		@media screen and (max-width: 1100px) {
			width: 100%;
		}

		.theadCSS {
			th:first-child {
				padding-left: 1.5em;
			}
		}

		table {
			width: 100%;
			td:first-child {
				width: 60%;
				align-items: center;
				padding-left: 1.5em;
			}
			td:nth-child(2) {
				width: 15%;
				align-items: center;
				padding: 0.3em;
			}
			td:nth-child(3) {
				padding: 0;
			}
			td:nth-child(4) {
				padding-right: 1em;
			}
		}
	}
}
.progressChart {
	// min-width: 500px;
	// width: 500px;
	@include basicCard();

	min-height: 389px;

	.lineChartSelect {
		.flexRow {
			margin-top: 0.75em;
			margin-bottom: 0.35em;
		}
	}
	// canvas {
	// 	width: 100%;
	// 	height: 500px;
	// }
}

///////////
// ERROR //
///////////
.errorPage {
	@include basicCard();
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 1.5em;

	img {
		width: auto;
		height: 8.5em;
	}

	.errorText {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 2em;
		width: 100%;

		p {
			margin: 0.1em 0;
		}
	}
}

///////////
// STATS //
///////////
.stats {
	.combinedTitle {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		gap: 0.2em;
	}
	h4 {
		font-size: 0.7em;
		padding-bottom: 0.2em;
		font-weight: normal;
	}
	.overviewTable {
		tr {
			&:first-child {
				border-radius: 0.7em 0.7em 0.7em 0.7em;
				td:first-child {
					border-radius: 0.7em 0 0 0;
				}
				td:last-child {
					border-radius: 0 0.7em 0.7em 0;
				}
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}

	.ranking {
		@include basicCard();

		td {
			height: 2.5em;
		}
		td:first-child {
			width: 3em;
			align-items: center;
		}
		td:last-child {
			width: 3em;
			align-items: right;
			text-align: right;
		}

		.medal {
			width: 2em;
			height: 2em;
			margin-bottom: -0.5em;
			margin-left: 0.2em;
		}
		.userRank {
			width: 3em;
			text-align: center;
			color: hsl($hue-primary, $saturation-low, $brightness-high - 10);
			font-weight: 600;
		}
	}
}

.statsCards {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 1.5em;
	width: 100%;
	position: relative;

	.card {
		@include basicCard();
		width: 100%;
		display: flex;
		@media screen and (max-width: 400px) {
			flex-direction: column;
			align-items: left;
			img {
				margin: 0 auto;
			}
		}
		@media screen and (min-width: 401px) {
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}
		position: relative;
		gap: 1em;
		padding: 1.5em;

		img {
			width: 3em;
			height: 3em;
		}
		.cardText {
			h2 {
				font-size: 1.3em;
				margin: 0;
				padding: 0;
			}
			p {
				font-size: 0.9em;
				margin: 0;
				margin-top: -0.2em;
				padding: 0;
			}
		}
	}
}

.progressCard {
	@include basicCard();
	padding: 1.5em;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1em;

	.modules {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 1em;
		height: 2em;
		align-items: center;
		cursor: pointer;

		&:hover {
			opacity: 0.7;
		}
	}

	.smallModuleImg {
		width: 1.2em;
		height: 1.2em;
		margin-bottom: -0.2em;
	}
	.title {
		width: calc(40% - 2.2em);
	}

	.progressBar {
		border-radius: 1em;
		background-color: white;
		height: 0.5em;
		min-width: 50%;
		box-shadow:
			inset rgba(5, 71, 68, 0.1) 0px 0px 4px,
			inset rgba(5, 71, 68, 0.05) 0px 0px 8px,
			0 0px 4px rgba(0, 0, 0, 0.1);
		z-index: 1;

		.progress {
			background-color: hsl($hue-correct, $saturation-low - 10, $brightness-high - 10);
			border-radius: 1em;
			height: 100%;
			box-shadow:
				inset rgba(5, 71, 68, 0.1) 0px 0px 4px,
				inset rgba(5, 71, 68, 0.05) 0px 0px 8px;
		}
	}

	form {
		gap: 0;
	}
}
.moduleInfo {
	@include modalBase();
	display: none;
	z-index: 0;
	&.show {
		display: flex;
		justify-content: center;
		z-index: 0;
	}
	&-content {
		@include modalContent();
		background: $gradient-bg;
		display: flex;
		flex-direction: column;
		gap: 2em;
	}

	.moduleInfoHeader {
		display: flex;
		flex-direction: row;
		gap: 1em;
		align-items: center;

		.moduleInfoImg {
			width: 2em;
			height: 2em;
		}
	}
	tr {
		cursor: default;
		&:hover {
			opacity: 1;
		}
	}

	th {
		text-align: center;
		&:first-child {
			border-radius: 0.7em 0 0 0;
		}
		&:last-child {
			border-radius: 0 0.7em 0 0;
		}
	}
	td {
		text-align: right;

		&:first-child {
			text-align: left;
			padding-left: 2em;
		}
		&:last-child {
			padding-right: 2em;
		}
		sup {
			font-size: 0.8em;
		}
	}
}

/////////////////
// CREATE EXAM //
/////////////////
.selectButtons {
	@include flexWrapSpaceAround();
	gap: 2em;
	position: relative;

	.buttonChoice {
		cursor: pointer;
		text-align: center;
		flex: 0 0 calc(12em - 2em);
		min-width: 3em;
		min-height: 3em;
		justify-content: center;
		align-items: center;
		display: flex;
		padding: 1em 1em;
		border-radius: 0.7em;
		font-weight: 500;
		background: $gradient-transparant_white;
		opacity: 0.9;

		&:hover {
			opacity: 0.7;
			background: linear-gradient(
				165deg,
				hsl($hue-link + 10, $saturation-medium, $brightness-medium + 20) 0%,
				hsl($hue-primary - 10, $saturation-medium, $brightness-medium + 20) 100%
			);
		}

		&:active {
			transition: $transition-medium;
			background: linear-gradient(
				165deg,
				hsl($hue-link + 10, $saturation-medium, $brightness-medium) 0%,
				hsl($hue-primary - 10, $saturation-medium, $brightness-medium) 100%
			);
			transform: scale(0.99);
		}
	}

	.selected {
		opacity: 1;
		background: linear-gradient(
			165deg,
			hsl($hue-link + 10, $saturation-medium - 10, $brightness-medium + 15) 0%,
			hsl($hue-primary - 10, $saturation-medium - 10, $brightness-medium + 15) 100%
		);
		transition: $transition-fast;
		color: $color-white;
		box-shadow: 0 0px 16px rgba(0, 0, 0, 0.1);
	}
}
.examDetails {
	display: flex;
	flex-direction: row;
	gap: 2em;
	align-items: flex-start;

	& > div {
		max-width: 50%;
	}

	@media screen and (max-width: 900px) {
		flex-direction: column;

		& > div {
			max-width: 100%;
		}
	}

	.selected {
		font-weight: 600;
	}
}
.examOption {
	width: 30%;
	align-self: flex-end;
}
.downloadResultsButtons {
	display: flex;
	flex-direction: row;
	gap: 1em;
	justify-content: space-between;
	width: 100%;
	padding: 1em 0;

	.downloadContainer {
		display: flex;
		flex-direction: row;
		gap: 0.5em;
		align-items: center;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}

	img {
		width: auto;
		height: 1.3em;
	}
}
//////////////
// SETTINGS //
//////////////
.settings {
	input,
	textarea {
		margin: 0.2em 0 1.5em 0;
	}
	.progressCard {
		padding: 2em;
		gap: 0;

		.userDetails {
			margin-bottom: 1em;
		}
	}
	.toggleTrigger {
		cursor: pointer;
	}
}
.flag {
	width: 2em;
	height: auto;
	cursor: pointer;
}
.flags {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-top: 1em;
	gap: 1em;
}
.flagActive {
	transform: scale(1.15);
}
