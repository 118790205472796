*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus {
  border: none;
  outline: none;
}

*:active {
  outline: none;
}

* {
  -webkit-tap-highlight-color: transparent;
  caret-color: transparent; /* Hides the blinking cursor */
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: poppins, sans-serif;
  background: linear-gradient(45deg, hsl(240, 40%, 90%) 0%, hsl(200, 40%, 90%) 100%);
}

.App {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.center {
  max-width: 1200px;
  width: calc(100vw - 8px);
  font-weight: 400;
  color: hsl(220, 50%, 40%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 300px) {
  .center {
    font-size: 0.7em;
  }
}
@media screen and (min-width: 301px) and (max-width: 499px) {
  .center {
    font-size: 0.8em;
  }
}
@media screen and (min-width: 500px) {
  .center {
    font-size: 0.9em;
  }
}

.left,
.right {
  height: 100vh;
  width: calc((100vw - 1200px) / 2);
  grid-row: 1/3; /* Spans from the first to the second row */
  z-index: 12;
  z-index: 12;
}

main {
  flex-grow: 1;
  padding: 2em;
  z-index: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 2em;
}

h1,
h2,
h3,
h4,
h5,
p,
table {
  margin: 0;
  padding: 0;
}

p {
  margin: 0.25em 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

table,
th,
td {
  border: none;
}

th {
  padding: 0;
  text-align: left;
  color: hsl(220, 50%, 40%);
  font-weight: 500;
}

.clickableTableRow {
  cursor: pointer;
}
.clickableTableRow:hover {
  opacity: 0.7;
}

sup {
  font-size: 0.5em;
  line-height: 1em;
  margin-top: -0.5em;
}

h1 {
  font-size: 1.3em;
  letter-spacing: 0.1rem;
  font-weight: 400;
}

h2 {
  font-size: 1.3em;
  letter-spacing: 0.1rem;
  font-weight: 400;
  color: hsl(220, 60%, 50%);
}

h3 {
  font-size: 1em;
  line-height: 1.5;
  font-weight: 400;
  color: hsl(220, 50%, 40%);
}

h5 {
  font-size: 0.5em;
  font-weight: 600;
}

a,
.link {
  text-decoration: none;
  color: hsl(195, 70%, 40%);
  font-weight: 500;
  transition: 0.5s;
  cursor: pointer;
}
a:hover,
.link:hover {
  color: hsl(195, 60%, 60%);
}

.selectedLink {
  text-decoration: none;
  color: hsl(220, 40%, 60%);
  font-weight: 600;
  transition: 0.5s;
  cursor: pointer;
}
.selectedLink:hover {
  color: hsl(195, 60%, 60%);
}

.error {
  color: hsl(350, 60%, 60%);
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1em;
}

.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.checkboxFlex {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.closeModal {
  position: absolute;
  right: 1em;
  top: 0;
  font-weight: 100;
  font-size: 2em;
  cursor: pointer;
}

.flatIcon {
  height: 0.7em;
  width: auto;
}

.alignRight {
  text-align: right;
}

.basicCard {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
}

.arrow {
  width: 1em;
  height: 2em;
  cursor: pointer;
  transition: 1s;
}
.arrow:hover {
  transition: 0.5s;
  transform: scale(1.05);
}
.arrow:active {
  transition: 0.1s;
  transform: scale(0.9);
}

.arrowDisabled {
  opacity: 0.2;
  cursor: default;
}

.progressBar {
  margin-top: 0.5em;
  border-radius: 1em;
  background-color: white;
  height: 0.5em;
  width: 100%;
  box-shadow: inset rgba(5, 71, 68, 0.1) 0px 0px 4px, inset rgba(5, 71, 68, 0.05);
  z-index: 1;
}
.progressBar .progress {
  background-color: hsl(170, 30%, 70%);
  border-radius: 1em;
  height: 100%;
  box-shadow: inset rgba(5, 71, 68, 0.1) 0px 0px 4px, inset rgba(5, 71, 68, 0.05) 0px 0px 8px;
}

.progressOne {
  height: 0.8em;
}
.progressOne progress {
  background-color: hsl(170, 30%, 70%);
}

.progressTwo {
  height: 0.7em;
}
.progressTwo .progress {
  background-color: hsl(200, 30%, 70%);
}

.progressThree {
  height: 0.6em;
}
.progressThree .progress {
  background-color: hsl(230, 30%, 70%);
}

.progressFour {
  height: 0.5em;
}
.progressFour .progress {
  background-color: hsl(260, 30%, 70%);
}

.monospace {
  font-family: "Droid Sans Mono", monospace;
  font-weight: 600;
  color: hsl(var(--hue-primary), var(--saturation-low), var(--medium));
  font-size: 0.95em;
}

input,
button,
select,
textarea {
  width: 100%;
  padding: 1.2em 1.5em 1em 1.5em;
  border-radius: 0.5em;
  border: none;
  font-size: 1em;
  font-family: poppins, sans-serif;
  caret-color: black;
}

input {
  margin: 0;
  height: 3em;
  caret-color: black;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}
input[type=checkbox] {
  width: 1.3em;
  height: 1.3em;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}
input[type=file] {
  padding: 0;
  box-shadow: none;
  border-radius: 0;
}

textarea {
  resize: none;
  height: 5em;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0;
}

select {
  padding: 0 1.3em;
  color: hsl(220, 50%, 40%);
  margin: 0;
  height: 3em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.selectMultiple {
  height: 20em;
}

button {
  margin: 0;
  background: linear-gradient(165deg, hsl(185, 50%, 60%) 0%, hsl(230, 50%, 60%) 100%);
  color: hsl(220, 27%, 96%);
  font-size: 1em;
  font-weight: 600;
  transition: 0.5s;
  box-shadow: inset rgba(17, 17, 26, 0.1) 0px 0px 10px, inset rgba(17, 17, 26, 0.1) 0px 0px 80px;
}
button:hover {
  background: linear-gradient(145deg, hsl(185, 50%, 60%) 0%, hsl(230, 50%, 60%) 100%);
  cursor: pointer;
  box-shadow: none;
}
button:active {
  background: linear-gradient(145deg, hsl(185, 50%, 55%) 0%, hsl(230, 50%, 60%) 100%);
  cursor: pointer;
  box-shadow: inset rgba(17, 17, 26, 0.5) 0px 0px 2px, inset rgba(17, 17, 26, 0.1) 0px 0px 4px, inset rgba(17, 17, 26, 0.05) 0px 0px 8px;
  transition: 0.1s;
  transform: scale(0.99);
}
button.redButton {
  background: linear-gradient(165deg, hsl(355, 55%, 55%) 0%, hsl(5, 55%, 40%) 100%);
}
button.redButton:hover {
  background: linear-gradient(145deg, hsl(355, 60%, 55%) 0%, hsl(5, 60%, 40%) 100%);
}
button.redButton:active {
  background: linear-gradient(165deg, hsl(355, 55%, 55%) 0%, hsl(5, 55%, 40%) 100%);
  box-shadow: inset rgba(17, 17, 26, 0.5) 0px 0px 2px, inset rgba(17, 17, 26, 0.1) 0px 0px 4px, inset rgba(17, 17, 26, 0.05) 0px 0px 8px;
  transform: scale(0.99);
}

.buttonDisabled {
  background: linear-gradient(165deg, hsl(220, 25%, 80%) 0%, hsl(220, 35%, 75%) 100%);
  cursor: default;
  color: hsl(220, 27%, 96%);
  box-shadow: none;
  transition: 1s;
}
.buttonDisabled:hover {
  cursor: default;
  background: linear-gradient(165deg, hsl(220, 25%, 80%) 0%, hsl(220, 35%, 75%) 100%);
  box-shadow: none;
}
.buttonDisabled:active {
  background: linear-gradient(165deg, hsl(220, 25%, 80%) 0%, hsl(220, 35%, 75%) 100%);
  box-shadow: none;
  transform: none;
}

.imageLink {
  transition: 0.5s;
  opacity: 0.8;
}
.imageLink:hover {
  transition: 0.5s;
  opacity: 1;
}
.imageLink:active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: 1s;
  opacity: 1;
  box-shadow: none;
}
.imageLink:active img {
  transform: scale(0.99);
}

.disabledImageLink {
  transition: 1s;
  opacity: 0.3;
  cursor: default;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.3;
}

form {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.displayNone {
  display: none;
}

.buttonOpen {
  color: hsl(220, 50%, 40%);
  border: 2px solid hsl(220, 50%, 40%);
  border-radius: 0.5em;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(-165deg, hsl(220, 40%, 90%) 0%, transparent 100%);
  transition: 0.5s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
.buttonOpen:hover {
  background: linear-gradient(-15deg, hsl(220, 40%, 80%) 0%, hsl(220, 40%, 90%) 100%);
  transition: 0.5s;
}
.buttonOpen:active {
  background: linear-gradient(-135deg, hsl(220, 40%, 90%) 0%, transparent 100%);
  transition: 0.5s;
  transform: scale(0.98);
}

.buttonOpenSelected {
  background: linear-gradient(-15deg, hsl(220, 40%, 70%) 0%, hsl(220, 40%, 80%) 100%);
  transition: 0.5s;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: hsl(220, 40%, 70%);
  border-radius: 20px;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(207, 210, 228, 0.7);
  cursor: pointer;
  flex-grow: 1;
  height: 100%;
  z-index: 100;
}

.ring {
  margin: auto;
  margin-top: 10%;
  border: 15px solid transparent;
  border-radius: 50%;
  border-left: 15px solid hsl(210, 40%, 70%);
  width: 200px;
  height: 200px;
  animation: spinner 3s linear infinite;
}

.ring2 {
  margin: auto;
  margin-left: 15px;
  margin-top: 15px;
  border: 15px solid transparent;
  border-radius: 50%;
  border-left: 15px solid hsl(220, 40%, 70%);
  width: 140px;
  height: 140px;
  animation: spinner 5s linear infinite;
}

.ring3 {
  margin: auto;
  margin-top: 15px;
  margin-left: 15px;
  border: 15px solid transparent;
  border-radius: 50%;
  border-left: 15px solid hsl(230, 40%, 70%);
  width: 80px;
  height: 80px;
  animation: spinner 7s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.overviewTable {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 1em;
  background: hsl(220, 70%, 95%);
  padding: 0;
}
.overviewTable tr {
  height: 3em;
  margin: 0;
  cursor: default;
}
.overviewTable tr:nth-child(even) {
  background: linear-gradient(90deg, hsl(220, 70%, 95%) 0%, hsl(220, 70%, 95%) 0%);
}
.overviewTable tr:nth-child(even) td:last-child {
  border-radius: 0 0.7em 0.7em 0;
}
.overviewTable tr:nth-child(odd) {
  border-radius: 0.7em;
  background-color: hsl(220, 27%, 96%);
}
.overviewTable tr:nth-child(odd) td:last-child {
  border-radius: 0 0.7em 0.7em 0;
}
.overviewTable tr:first-child {
  border-radius: 0 0 0.7em 0.7em;
}
.overviewTable tr:first-child td:last-child {
  border-radius: 0 0 0.7em 0;
}
.overviewTable tr:first-child:hover {
  opacity: 1;
}
.overviewTable tr:last-child {
  border-radius: 0 0 0.7em 0.7em;
}
.overviewTable tr:last-child td:first-child {
  border-radius: 0 0 0 0.7em;
}
.overviewTable .clickableTableRow {
  cursor: pointer;
}
.overviewTable .clickableTableRow:hover {
  opacity: 0.8;
}
.overviewTable .theadCSS tr {
  background: linear-gradient(0deg, hsl(210, 50%, 60%) 0%, hsl(230, 40%, 60%) 100%);
}
.overviewTable .theadCSS tr th {
  padding: 0 1em;
  color: hsl(220, 27%, 96%);
  font-weight: 600;
  width: 1200px;
  transition: 0.5s;
}
.overviewTable .theadCSS tr th:active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}
.overviewTable .theadCSS tr th:first-child {
  padding-right: 0em;
}
.overviewTable .sortableTable th:first-child {
  min-width: 7em;
}
.overviewTable .sortableTable th:hover {
  transition: 0.1s;
  background: linear-gradient(165deg, hsl(210, 50%, 70%) 0%, hsl(230, 40%, 70%) 100%);
}
.overviewTable .theadCSSRounded tr {
  border-radius: 0.7em;
}
.overviewTable .theadCSSRounded tr th:first-child {
  border-radius: 0.7em 0 0 0;
}
.overviewTable .theadCSSRounded tr th:last-child {
  border-radius: 0 0.7em 0 0;
}
.overviewTable .tableImg {
  width: 4em;
  height: 2em;
  padding-right: 1em;
}
.overviewTable td {
  padding: 0 1em;
  align-items: center;
}
.overviewTable td:first-child {
  width: 2em;
  padding-right: 0em;
}
.overviewTable td:last-child {
  text-align: right;
}
.overviewTable td img {
  width: 2em;
  height: 2em;
}
.overviewTable .progressBar {
  margin: 0;
}

@media screen and (max-width: 900px) {
  .overviewTable th:nth-child(4):not(:last-child),
  .overviewTable td:nth-child(4):not(:last-child),
  .overviewTable th:nth-child(5):not(:last-child),
  .overviewTable td:nth-child(5):not(:last-child) {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .overviewTable th:nth-child(3):not(:last-child),
  .overviewTable td:nth-child(3):not(:last-child) {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .overviewTable th:nth-child(6):not(:last-child),
  .overviewTable td:nth-child(6):not(:last-child) {
    display: none;
  }
}
.teacherPortal .classSelector {
  display: flex;
  gap: 2em;
}
.teacherPortal .classSelector .selectClass {
  font-weight: 300;
  cursor: pointer;
  color: hsl(220, 50%, 40%);
}
.teacherPortal .overviewOptions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  border-radius: 1em 1em 0 0;
}
.teacherPortal .overviewOptions h2,
.teacherPortal .overviewOptions .current {
  color: white;
  background: linear-gradient(0, hsl(210, 50%, 70%) 0%, hsl(230, 40%, 70%) 100%);
  text-decoration: none;
  width: 100%;
  text-align: center;
  padding: 0.7em;
  border-radius: 1em 0 0 0;
  margin-left: -1em;
  transition: 0.5s;
  height: 3em;
  max-height: 3em;
}
.teacherPortal .overviewOptions h2:hover {
  background: linear-gradient(180deg, hsl(210, 50%, 60%) 0%, hsl(230, 40%, 60%) 100%);
  transition: 0.5s;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}
.teacherPortal .overviewOptions h2:active {
  background: linear-gradient(180deg, hsl(210, 50%, 60%) 0%, hsl(230, 50%, 60%) 100%);
  transition: 0.5s;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}
.teacherPortal .overviewOptions h2:first-child {
  margin-left: 0;
}
.teacherPortal .overviewOptions h2:last-child {
  border-radius: 1em 1em 0 0;
}
.teacherPortal .overviewOptions .current {
  background: linear-gradient(180deg, hsl(210, 50%, 60%) 0%, hsl(230, 40%, 60%) 100%);
}
.teacherPortal .createNewClass {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.teacherPortal .editClass {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.buttonRight {
  width: 40%;
  float: right;
}

.loginPage {
  width: min(700px, 100vw);
  margin: auto;
}

.loginSignup.is-safari .eyeIcon {
  display: none;
}

.loginSignup {
  max-width: 700px;
  min-width: min(700px, 100%);
  margin: auto;
}
.loginSignup .checkboxWrapper {
  display: flex;
  flex-direction: row;
  gap: 1em;
}
.loginSignup .passwordField {
  position: relative;
  z-index: 0;
}
.loginSignup .passwordField .eyeIcon {
  position: absolute;
  right: 0;
  width: calc(16px + 2.4em);
  height: calc(16px + 2.4em);
  padding: 1.2em;
  cursor: pointer;
}
.loginSignup .passwordField .eyeIcon:hover {
  opacity: 0.8;
}
.loginSignup .legalDisclaimer {
  color: hsl(220, 50%, 40%);
  font-size: 0.8em;
  opacity: 0.7;
  max-width: 400px;
}
.loginSignup .legalDisclaimer:hover {
  opacity: 1;
  color: hsl(195, 70%, 40%);
}

.navigationLabel {
  text-align: center;
}

.studentProgress sup {
  font-size: 0.7em;
}
.studentProgress .flexRowProgress {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  gap: 2em;
}
@media screen and (max-width: 1100px) {
  .studentProgress .flexRowProgress {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.studentProgress .column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2em;
  width: calc(max(1200px, 100vw) / 2 - 4em);
}
@media screen and (max-width: 1100px) {
  .studentProgress .column {
    width: 100%;
  }
}
.studentProgress .column .theadCSS th:first-child {
  padding-left: 1.5em;
}
.studentProgress .column table {
  width: 100%;
}
.studentProgress .column table td:first-child {
  width: 60%;
  align-items: center;
  padding-left: 1.5em;
}
.studentProgress .column table td:nth-child(2) {
  width: 15%;
  align-items: center;
  padding: 0.3em;
}
.studentProgress .column table td:nth-child(3) {
  padding: 0;
}
.studentProgress .column table td:nth-child(4) {
  padding-right: 1em;
}

.progressChart {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  min-height: 389px;
}
.progressChart .lineChartSelect .flexRow {
  margin-top: 0.75em;
  margin-bottom: 0.35em;
}

.errorPage {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1.5em;
}
.errorPage img {
  width: auto;
  height: 8.5em;
}
.errorPage .errorText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2em;
  width: 100%;
}
.errorPage .errorText p {
  margin: 0.1em 0;
}

.stats .combinedTitle {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.2em;
}
.stats h4 {
  font-size: 0.7em;
  padding-bottom: 0.2em;
  font-weight: normal;
}
.stats .overviewTable tr:first-child {
  border-radius: 0.7em 0.7em 0.7em 0.7em;
}
.stats .overviewTable tr:first-child td:first-child {
  border-radius: 0.7em 0 0 0;
}
.stats .overviewTable tr:first-child td:last-child {
  border-radius: 0 0.7em 0.7em 0;
}
.stats .overviewTable tr:first-child:hover {
  opacity: 0.8;
}
.stats .ranking {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
}
.stats .ranking td {
  height: 2.5em;
}
.stats .ranking td:first-child {
  width: 3em;
  align-items: center;
}
.stats .ranking td:last-child {
  width: 3em;
  align-items: right;
  text-align: right;
}
.stats .ranking .medal {
  width: 2em;
  height: 2em;
  margin-bottom: -0.5em;
  margin-left: 0.2em;
}
.stats .ranking .userRank {
  width: 3em;
  text-align: center;
  color: hsl(220, 40%, 70%);
  font-weight: 600;
}

.statsCards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1.5em;
  width: 100%;
  position: relative;
}
.statsCards .card {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
  display: flex;
  position: relative;
  gap: 1em;
  padding: 1.5em;
}
@media screen and (max-width: 400px) {
  .statsCards .card {
    flex-direction: column;
    align-items: left;
  }
  .statsCards .card img {
    margin: 0 auto;
  }
}
@media screen and (min-width: 401px) {
  .statsCards .card {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}
.statsCards .card img {
  width: 3em;
  height: 3em;
}
.statsCards .card .cardText h2 {
  font-size: 1.3em;
  margin: 0;
  padding: 0;
}
.statsCards .card .cardText p {
  font-size: 0.9em;
  margin: 0;
  margin-top: -0.2em;
  padding: 0;
}

.progressCard {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
}
.progressCard .modules {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
  height: 2em;
  align-items: center;
  cursor: pointer;
}
.progressCard .modules:hover {
  opacity: 0.7;
}
.progressCard .smallModuleImg {
  width: 1.2em;
  height: 1.2em;
  margin-bottom: -0.2em;
}
.progressCard .title {
  width: calc(40% - 2.2em);
}
.progressCard .progressBar {
  border-radius: 1em;
  background-color: white;
  height: 0.5em;
  min-width: 50%;
  box-shadow: inset rgba(5, 71, 68, 0.1) 0px 0px 4px, inset rgba(5, 71, 68, 0.05) 0px 0px 8px, 0 0px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.progressCard .progressBar .progress {
  background-color: hsl(170, 30%, 70%);
  border-radius: 1em;
  height: 100%;
  box-shadow: inset rgba(5, 71, 68, 0.1) 0px 0px 4px, inset rgba(5, 71, 68, 0.05) 0px 0px 8px;
}
.progressCard form {
  gap: 0;
}

.moduleInfo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(207, 210, 228, 0.7);
  cursor: pointer;
  display: none;
  z-index: 0;
}
.moduleInfo.show {
  display: flex;
  justify-content: center;
  z-index: 0;
}
.moduleInfo-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  margin-top: 4em;
  padding: 2em;
  border-radius: 1em;
  max-height: 85vh;
  width: calc(100% - 4em);
  max-width: calc(1200px - 4em);
  background: linear-gradient(45deg, hsl(240, 40%, 90%) 0%, hsl(200, 40%, 90%) 100%);
  box-shadow: 0 0 64px rgba(0, 0, 0, 0.15), 0 0 8px rgba(0, 0, 0, 0.05);
  cursor: default;
  z-index: 100;
  background: linear-gradient(45deg, hsl(240, 40%, 90%) 0%, hsl(200, 40%, 90%) 100%);
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.moduleInfo .moduleInfoHeader {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
}
.moduleInfo .moduleInfoHeader .moduleInfoImg {
  width: 2em;
  height: 2em;
}
.moduleInfo tr {
  cursor: default;
}
.moduleInfo tr:hover {
  opacity: 1;
}
.moduleInfo th {
  text-align: center;
}
.moduleInfo th:first-child {
  border-radius: 0.7em 0 0 0;
}
.moduleInfo th:last-child {
  border-radius: 0 0.7em 0 0;
}
.moduleInfo td {
  text-align: right;
}
.moduleInfo td:first-child {
  text-align: left;
  padding-left: 2em;
}
.moduleInfo td:last-child {
  padding-right: 2em;
}
.moduleInfo td sup {
  font-size: 0.8em;
}

.selectButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2em;
  position: relative;
}
.selectButtons .buttonChoice {
  cursor: pointer;
  text-align: center;
  flex: 0 0 10em;
  min-width: 3em;
  min-height: 3em;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 1em 1em;
  border-radius: 0.7em;
  font-weight: 500;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  opacity: 0.9;
}
.selectButtons .buttonChoice:hover {
  opacity: 0.7;
  background: linear-gradient(165deg, hsl(205, 60%, 80%) 0%, hsl(210, 60%, 80%) 100%);
}
.selectButtons .buttonChoice:active {
  transition: 0.5s;
  background: linear-gradient(165deg, hsl(205, 60%, 60%) 0%, hsl(210, 60%, 60%) 100%);
  transform: scale(0.99);
}
.selectButtons .selected {
  opacity: 1;
  background: linear-gradient(165deg, hsl(205, 50%, 75%) 0%, hsl(210, 50%, 75%) 100%);
  transition: 0.1s;
  color: hsl(0, 0%, 100%);
  box-shadow: 0 0px 16px rgba(0, 0, 0, 0.1);
}

.examDetails {
  display: flex;
  flex-direction: row;
  gap: 2em;
  align-items: flex-start;
}
.examDetails > div {
  max-width: 50%;
}
@media screen and (max-width: 900px) {
  .examDetails {
    flex-direction: column;
  }
  .examDetails > div {
    max-width: 100%;
  }
}
.examDetails .selected {
  font-weight: 600;
}

.examOption {
  width: 30%;
  align-self: flex-end;
}

.downloadResultsButtons {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: space-between;
  width: 100%;
  padding: 1em 0;
}
.downloadResultsButtons .downloadContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;
  cursor: pointer;
}
.downloadResultsButtons .downloadContainer:hover {
  opacity: 0.8;
}
.downloadResultsButtons img {
  width: auto;
  height: 1.3em;
}

.settings input,
.settings textarea {
  margin: 0.2em 0 1.5em 0;
}
.settings .progressCard {
  padding: 2em;
  gap: 0;
}
.settings .progressCard .userDetails {
  margin-bottom: 1em;
}
.settings .toggleTrigger {
  cursor: pointer;
}

.flag {
  width: 2em;
  height: auto;
  cursor: pointer;
}

.flags {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 1em;
  gap: 1em;
}

.flagActive {
  transform: scale(1.15);
}

header {
  position: sticky;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  color: hsl(220, 50%, 40%);
  min-height: 3em;
  height: 3em;
  max-height: 3em;
  z-index: 10;
  position: relative;
}
header #logo {
  cursor: pointer;
  position: absolute;
  width: 1em;
  height: 2em;
  left: 2em;
  top: 0.5em;
  transition: 0.5s;
}
header #logo:hover {
  transition: 0.5s;
}
header #logo:active {
  transform: scale(0.95);
  transition: 0.1s;
}
header nav {
  position: absolute;
  top: 0;
  right: 2em;
  width: calc(100% - 4em - 2em);
  display: flex;
  justify-content: space-between;
  color: hsl(220, 50%, 40%);
  height: 100%;
  align-items: center;
  text-align: center;
}
header a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: hsl(220, 50%, 40%);
  transition: 0.5s;
  height: 100%;
  padding: 1em;
}
header a:hover {
  transition: 0.5s;
}

footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: calc(1200px - 4em);
  width: calc(100vw - 4em);
  margin: 0 2em;
  padding: 1.5em 2em;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  border-radius: 0.7em 0.7em 0 0;
  align-items: center;
}
footer nav {
  display: flex;
  flex-direction: row;
  gap: 2em;
  color: hsl(195, 70%, 40%);
}
footer a {
  font-weight: 400;
  opacity: 0.7;
}
footer a:hover {
  opacity: 1;
}
footer a:active {
  opacity: 1;
  transform: scale(0.95);
}
footer .rights {
  text-align: right;
  margin: 0;
}

.createNewContent {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.createNewContent .flexRow h3 {
  width: 20em;
}

.dateTimePicker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 2em;
  position: relative;
  align-items: flex-end;
}
.dateTimePicker .datePicker {
  width: 100%;
}
.dateTimePicker .timePicker {
  min-width: 6.1em;
}
@media screen and (max-width: 800px) {
  .dateTimePicker {
    flex-direction: column;
  }
  .dateTimePicker .timePicker {
    width: 100%;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(207, 210, 228, 0.7);
  cursor: pointer;
  display: none;
  z-index: 10;
}
.modal.show {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  margin-top: 4em;
  padding: 2em;
  border-radius: 1em;
  max-height: 85vh;
  width: calc(100% - 4em);
  max-width: calc(1200px - 4em);
  background: linear-gradient(45deg, hsl(240, 40%, 90%) 0%, hsl(200, 40%, 90%) 100%);
  box-shadow: 0 0 64px rgba(0, 0, 0, 0.15), 0 0 8px rgba(0, 0, 0, 0.05);
  cursor: default;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.modalNavigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2em;
  margin-top: 0.5em;
}

.alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(207, 210, 228, 0.7);
  cursor: pointer;
  z-index: 101;
}
.alert .alert-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  margin-top: 4em;
  padding: 2em;
  border-radius: 1em;
  max-height: 85vh;
  width: calc(100% - 4em);
  max-width: calc(1200px - 4em);
  background: linear-gradient(45deg, hsl(240, 40%, 90%) 0%, hsl(200, 40%, 90%) 100%);
  box-shadow: 0 0 64px rgba(0, 0, 0, 0.15), 0 0 8px rgba(0, 0, 0, 0.05);
  cursor: default;
  z-index: 100;
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(242, 244, 248, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  width: calc(100% - 4em);
  max-width: 500px;
  padding: 2.5em;
  top: 20vh;
  opacity: 1;
  background: linear-gradient(135deg, rgb(242, 244, 248) 0%, rgb(255, 255, 255) 100%);
}
.alert .alert-content button {
  margin-top: 1.5em;
}
.alert .success h2 {
  color: hsl(170, 30%, 40%);
}
.alert .success button {
  background: linear-gradient(175deg, hsl(175, 40%, 55%) 0%, hsl(165, 40%, 60%) 100%);
}
.alert .error {
  color: hsl(220, 50%, 40%);
}
.alert .error h2 {
  color: hsl(0, 40%, 40%);
}
.alert .error button {
  background: linear-gradient(165deg, hsl(355, 55%, 55%) 0%, hsl(5, 55%, 40%) 100%);
  color: white;
}

.close {
  cursor: pointer;
  float: right;
}

.spiderChartContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.spiderChartContainer .spiderChart {
  margin-top: -80px;
}
.spiderChartContainer .spiderChart canvas {
  height: 400px;
  width: 400px;
}

