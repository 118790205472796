*,
*:before,
*:after {
	box-sizing: border-box;
}

// TODO: site is harder to navigate when just using keyboard, is that a problem?
*:focus {
	border: none;
	outline: none;
}
*:active {
	outline: none;
}
* {
	-webkit-tap-highlight-color: transparent;
	caret-color: transparent; /* Hides the blinking cursor */
	// -webkit-user-select: none;
	// -moz-user-select: none;
	// -ms-user-select: none;
	// user-select: none;
}

html,
body {
	margin: 0;
	padding: 0;
	font-family: poppins, sans-serif;
	// user-select: none;
	background: $gradient-bg;
}

.App {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.center {
	@include maxWidth();
	font-weight: 400;
	color: $color-primary;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;

	// Change the font-size based on breakpoints
	@media screen and (max-width: 300px) {
		font-size: 0.7em;
	}
	@media screen and (min-width: 301px) and (max-width: 499px) {
		font-size: 0.8em;
	}
	@media screen and (min-width: 500px) {
		font-size: 0.9em;
	}
}
.left,
.right {
	// background: $gradient-sides;
	height: 100vh;
	width: calc((100vw - $maxWidth) / 2);
	grid-row: 1 / 3; /* Spans from the first to the second row */
	z-index: 12;
	z-index: 12;
}
main {
	flex-grow: 1;
	padding: 2em;
	z-index: 0;
	overflow-y: auto;

	display: flex;
	flex-direction: column;
	gap: 2em;
}

h1,
h2,
h3,
h4,
h5,
p,
table {
	margin: 0;
	padding: 0;
}
p {
	margin: 0.25em 0;
}
table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}
table,
th,
td {
	border: none;
}
th {
	padding: 0;
	text-align: left;
	color: $color-primary;
	font-weight: 500;
}
.clickableTableRow {
	cursor: pointer;
	&:hover {
		opacity: 0.7;
	}
}
sup {
	font-size: 0.5em;
	line-height: 1em;
	margin-top: -0.5em;
}

h1 {
	font-size: 1.3em;
	letter-spacing: 0.1rem;
	font-weight: 400;
}
h2 {
	font-size: 1.3em;
	letter-spacing: 0.1rem;
	font-weight: 400;
	color: hsl($hue-primary, $saturation-medium, $brightness-medium - 10);
}
h3 {
	font-size: 1em;
	line-height: 1.5;
	font-weight: 400;
	color: $color-primary;
}

h5 {
	font-size: 0.5em;
	font-weight: 600;
}

a,
.link {
	text-decoration: none;
	color: $color-link;
	font-weight: 500;
	transition: $transition-medium;
	cursor: pointer;

	&:hover {
		color: $color-hover;
	}
}
.selectedLink {
	text-decoration: none;
	color: $color-active;
	font-weight: 600;
	transition: $transition-medium;
	cursor: pointer;

	&:hover {
		color: $color-hover;
	}
}

.error {
	color: hsl($hue-incorrect - 10, $saturation-medium, $brightness-medium);
}
.flexRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 1em;
}

.flexCol {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
}
.checkboxFlex {
	display: flex;
	flex-direction: row;
	gap: 1em;
}
.closeModal {
	position: absolute;
	right: 1em;
	top: 0;
	font-weight: 100;
	font-size: 2em;
	cursor: pointer;
}
.flatIcon {
	height: 0.7em;
	width: auto;
}
.alignRight {
	text-align: right;
}
.basicCard {
	@include basicCard();
}
.arrow {
	width: 1em;
	height: 2em;
	cursor: pointer;
	transition: $transition-slow;
	&:hover {
		transition: $transition-medium;
		transform: scale(1.05);
	}
	&:active {
		transition: $transition-fast;
		transform: scale(0.9);
	}
}
.arrowDisabled {
	opacity: 0.2;
	cursor: default;
}

.progressBar {
	margin-top: 0.5em;
	border-radius: 1em;
	background-color: white;
	height: 0.5em;
	width: 100%;
	box-shadow:
		inset rgba(5, 71, 68, 0.1) 0px 0px 4px,
		inset rgba(5, 71, 68, 0.05);
	z-index: 1;

	.progress {
		background-color: hsl($hue-correct, $saturation-low - 10, $brightness-high - 10);
		border-radius: 1em;
		height: 100%;
		box-shadow:
			inset rgba(5, 71, 68, 0.1) 0px 0px 4px,
			inset rgba(5, 71, 68, 0.05) 0px 0px 8px;
	}
}
.progressOne {
	// height: 3em;
	height: 0.8em;
	progress {
		background-color: hsl($hue-correct, $saturation-low - 10, $brightness-high - 10);
	}
}
.progressTwo {
	height: 0.7em;
	.progress {
		background-color: hsl($hue-correct + 30, $saturation-low - 10, $brightness-high - 10);
	}
}
.progressThree {
	height: 0.6em;
	.progress {
		background-color: hsl(($hue-correct + 60), $saturation-low - 10, $brightness-high - 10);
	}
}
.progressFour {
	height: 0.5em;
	.progress {
		background-color: hsl(($hue-correct + 90), $saturation-low - 10, $brightness-high - 10);
	}
}
.monospace {
	font-family: "Droid Sans Mono", monospace;
	font-weight: 600;
	color: hsl(var(--hue-primary), var(--saturation-low), var(--medium));
	font-size: 0.95em;
}

//////////////////////
//  INPUT & BUTTON  //
//////////////////////
input,
button,
select,
textarea {
	width: 100%;
	padding: 1.2em 1.5em 1em 1.5em;
	border-radius: 0.5em;
	border: none;
	font-size: 1em;
	font-family: poppins, sans-serif;
	caret-color: black;
}
input {
	margin: 0;
	height: 3em;
	caret-color: black;
	box-shadow: $standard-inset-box-shadow;
	font-size: $fontSizeInputFields;

	&[type="checkbox"] {
		width: 1.3em;
		height: 1.3em;
		box-shadow: $standard-inset-box-shadow;
	}

	&[type="file"] {
		padding: 0;
		box-shadow: none;
		border-radius: 0;
	}
}
textarea {
	resize: none;
	height: 5em;
	box-shadow: $standard-inset-box-shadow;
	margin: 0;
}
select {
	padding: 0 1.3em;
	color: $color-primary;
	margin: 0;
	height: 3em;
	box-shadow: $standard-box-shadow;
	// option {
	// 	color: $color-primary;
	// }
}
.selectMultiple {
	height: 20em;
}
// .selectMultipleOptions {
// 	width: 10em;
// }

button {
	margin: 0;
	background: $gradient-button;
	color: $color-off_white;
	font-size: 1em;
	font-weight: 600;
	transition: $transition-medium;
	box-shadow:
		inset rgba(17, 17, 26, 0.1) 0px 0px 10px,
		inset rgba(17, 17, 26, 0.1) 0px 0px 80px;

	&:hover {
		background: $gradient-button_hover;
		cursor: pointer;
		box-shadow: none;
	}

	&:active {
		background: $gradient-button_active;
		cursor: pointer;
		box-shadow: $complex-inset-box-shadow;
		transition: $transition-fast;
		transform: scale(0.99);
	}

	&.redButton {
		background: $gradient-incorrect;

		&:hover {
			background: $gradient-incorrect_reverse;
		}

		&:active {
			background: $gradient-incorrect;
			box-shadow: $complex-inset-box-shadow;
			transform: scale(0.99);
		}
	}
}
.buttonDisabled {
	background: $gradient-disabled;
	cursor: default;
	color: $color-off_white;
	box-shadow: none;
	transition: $transition-slow;
	&:hover {
		cursor: default;
		background: $gradient-disabled;
		box-shadow: none;
	}
	&:active {
		background: $gradient-disabled;
		box-shadow: none;
		transform: none;
	}
}
.imageLink {
	transition: $transition-medium;
	opacity: 0.8;
	&:hover {
		transition: $transition-medium;
		opacity: 1;
	}
	&:active {
		box-shadow: $standard-inset-box-shadow;
		transition: $transition-slow;
		opacity: 1;
		box-shadow: none;
		img {
			transform: scale(0.99);
		}
	}
}
.disabledImageLink {
	transition: $transition-slow;
	opacity: 0.3;
	cursor: default;
}
.disabled {
	pointer-events: none;
	cursor: default;
	opacity: 0.3;
}
form {
	display: flex;
	flex-direction: column;
	gap: 2em;
}

.displayNone {
	display: none;
}
.buttonOpen {
	color: $color-primary;
	border: 2px solid $color-primary;
	border-radius: 0.5em;
	padding: 0.5em;
	width: 100%;
	text-align: center;
	cursor: pointer;
	background: linear-gradient(
		-165deg,
		hsl($hue-primary, $saturation-low, $brightness-high + 10) 0%,
		transparent 100%
	);
	transition: $transition-medium;
	box-shadow: $standard-box-shadow;

	&:hover {
		background: linear-gradient(
			-15deg,
			hsl($hue-primary, $saturation-low, $brightness-high) 0%,
			hsl($hue-primary, $saturation-low, $brightness-high + 10) 100%
		);
		transition: $transition-medium;
	}
	&:active {
		background: linear-gradient(
			-135deg,
			hsl($hue-primary, $saturation-low, $brightness-high + 10) 0%,
			transparent 100%
		);
		transition: $transition-medium;
		transform: scale(0.98);
	}
}
.buttonOpenSelected {
	background: linear-gradient(
		-15deg,
		hsl($hue-primary, $saturation-low, $brightness-high - 10) 0%,
		hsl($hue-primary, $saturation-low, $brightness-high) 100%
	);
	transition: $transition-medium;
}
///////////////
// SCROLLBAR //
///////////////
// Works on Firefox
// * {
// scrollbar-width: thin;
// scrollbar-color: hsl($hue-primary, $saturation-low, $brightness-high - 10)
// 	hsl($hue-primary, $saturation-low, $brightness-high - 10);
// }

*::-webkit-scrollbar {
	width: $scrollbarWidth;
	height: 8px;
}

*::-webkit-scrollbar-thumb {
	background-color: hsl($hue-primary, $saturation-low, $brightness-high - 10);
	border-radius: 20px;
}
/////////////
// LOADING //
/////////////
.loading {
	@include modalBase();
	flex-grow: 1;
	height: 100%;
	z-index: 100;
}

.ring {
	margin: auto;
	margin-top: 10%;
	border: 15px solid transparent;
	border-radius: 50%;
	border-left: 15px solid hsl($hue-primary - 10, $saturation-low, $brightness-high - 10);
	width: 200px;
	height: 200px;
	animation: spinner 3s linear infinite;
}

.ring2 {
	margin: auto;
	margin-left: 15px;
	margin-top: 15px;
	border: 15px solid transparent;
	border-radius: 50%;
	border-left: 15px solid hsl($hue-primary, $saturation-low, $brightness-high - 10);
	width: 140px;
	height: 140px;
	animation: spinner 5s linear infinite;
}

.ring3 {
	margin: auto;
	margin-top: 15px;
	margin-left: 15px;
	border: 15px solid transparent;
	border-radius: 50%;
	border-left: 15px solid hsl($hue-primary + 10, $saturation-low, $brightness-high - 10);
	width: 80px;
	height: 80px;
	animation: spinner 7s linear infinite;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

////////////////////
// OVERVIEW TABLE //
////////////////////
.overviewTable {
	@include basicCard();
	border-radius: 1em;
	background: hsl($hue-primary, $saturation-high, 95%);
	padding: 0;

	tr {
		height: 3em;
		margin: 0;
		cursor: default;

		&:nth-child(even) {
			background: linear-gradient(
				90deg,
				hsl($hue-primary, $saturation-high, $brightness-high + 15) 0%,
				hsl($hue-primary, $saturation-high, 95%) 0%
			);
			td:last-child {
				border-radius: 0 0.7em 0.7em 0;
			}
		}
		&:nth-child(odd) {
			border-radius: 0.7em;
			background-color: $color-off_white;
			td:last-child {
				border-radius: 0 0.7em 0.7em 0;
			}
		}
		&:first-child {
			border-radius: 0 0 0.7em 0.7em;
			td:last-child {
				border-radius: 0 0 0.7em 0;
			}
			&:hover {
				opacity: 1;
			}
		}
		&:last-child {
			border-radius: 0 0 0.7em 0.7em;
			td:first-child {
				border-radius: 0 0 0 0.7em;
			}
		}
		// &:hover {
		// 	opacity: 0.8;
		// }
	}
	.clickableTableRow {
		cursor: pointer;
		&:hover {
			opacity: 0.8;
		}
	}
	.theadCSS tr {
		background: linear-gradient(
			0deg,
			hsl($hue-primary - 10, $saturation-low + 10, $brightness-medium) 0%,
			hsl($hue-primary + 10, $saturation-low, $brightness-medium) 100%
		);

		th {
			padding: 0 1em;
			color: $color-off_white;
			font-weight: 600;
			width: $maxWidth;
			transition: $transition-medium;
			&:active {
				box-shadow: $standard-inset-box-shadow;
				transition: $transition-medium;
			}
			&:first-child {
				padding-right: 0em;
			}
		}
	}
	.sortableTable th {
		&:first-child {
			min-width: 7em;
		}
		&:hover {
			transition: $transition-fast;
			background: linear-gradient(
				165deg,
				hsl($hue-primary - 10, $saturation-low + 10, $brightness-medium + 10) 0%,
				hsl($hue-primary + 10, $saturation-low, $brightness-medium + 10) 100%
			);
		}
	}
	.theadCSSRounded tr {
		border-radius: 0.7em;
		th {
			&:first-child {
				border-radius: 0.7em 0 0 0;
			}
			&:last-child {
				border-radius: 0 0.7em 0 0;
			}
		}
	}
	.tableImg {
		width: 4em;
		height: 2em;
		padding-right: 1em;
	}
	td {
		padding: 0 1em;
		align-items: center;

		&:first-child {
			width: 2em;
			padding-right: 0em;
		}
		&:last-child {
			text-align: right;
		}
		img {
			width: 2em;
			height: 2em;
		}
	}

	.progressBar {
		margin: 0;
	}
}

@media screen and (max-width: 900px) {
	.overviewTable th:nth-child(4):not(:last-child),
	.overviewTable td:nth-child(4):not(:last-child),
	.overviewTable th:nth-child(5):not(:last-child),
	.overviewTable td:nth-child(5):not(:last-child) {
		display: none;
	}
}

@media screen and (max-width: 700px) {
	.overviewTable th:nth-child(3):not(:last-child),
	.overviewTable td:nth-child(3):not(:last-child) {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.overviewTable th:nth-child(6):not(:last-child),
	.overviewTable td:nth-child(6):not(:last-child) {
		display: none;
	}
}
